<!--딜 불가기간 페이지 메인-->
<template>
	<div>
		<!--검색 조건 카드 시작-->
		<searchCard>
			<div class="inline-block w-1/2">
				<h6>등록일</h6>
				<div class="flex">
					<b-form-radio class="w-20" v-model="opt.period" name="searchRadioPeriod" value="">
						<span class="mr-2">전체</span>
					</b-form-radio>
					<b-form-radio v-model="opt.period" name="searchRadioPeriod" value="period" />
					<div class="flex -mt-1.5">
						<datePicker class="" model="opt.startDate" />
						<span class="p-2 lh-6">~</span>
						<datePicker class="" model="opt.endDate" />
					</div>
				</div>
			</div>
			<div class="inline-block w-1/2 pr-12">
				<h6 class="w-1/1">상품그룹 통합검색</h6>
				<div class="flex">
					<b-form-input
						class="w-1/1 mr-4"
						v-model.trim="opt.searchKeyword"
						name="keyword"
						placeholder="검색어"
						@keyup.enter="search"
					/>
					<b-button class="w-40 lh-3" @click="search">검색</b-button>
				</div>
			</div>
		</searchCard>

		<!--검색 결과 리스트 시작-->
		<b-card>
			<b-button class="w-30" @click="dealRestrictedDatePopup.open()">등록</b-button>
			<b-button class="absolute right-0" @click="excelExport">엑셀 다운로드 요청</b-button>
			<tb :inf="inf" :pr="ths" :res="res" />
		</b-card>
		<dealRestrictedDatePopup :pr="ths" cName="dealRestrictedDatePopup" />
	</div>
</template>

<script>
import dealRestrictedDatePopup from 'pages/dealRestrictedDatePopup'

const now = new Date(),
	searchKeyOpts = [
		{ label: 'idx', value: 'productGroupIdx', operation: 'EQUAL' },
		{
			label: '상품그룹',
			value: 'productGroupName',
		},
		{
			label: '브랜드',
			value: 'brandName',
		},
		{
			label: '파트너사',
			value: 'companyName',
		},
	],
	defOpt = {
		period: 'period',

		startDate: getMonth(now, -1),
		endDate: now,

		searchKeyword: '',
	}
//검색조건 기본값. 검색 조건 초기화를 위해 사용 opt로 치환됨

export default {
	components: { dealRestrictedDatePopup },
	data() {
		return {
			opt: { ...defOpt },
			defOpt,
			lastOpt: {},
			res: {},
			inf: [
				{ title: 'No', model: 'idx', size: 2 },
				{ title: 'idx', key: 'productGroupIdx', size: 3 },
				{ title: '브랜드', key: 'brandName', size: 2 },
				{ title: '상품그룹', key: 'productGroupName', size: 2 },
				{ title: '파트너사', key: 'companyName', size: 3 },
				{ title: '딜 불가기간', key: 'restrictedDate', size: 2 },
				{ title: '등록일', key: 'createDt', size: 2 },
				{ title: '삭제', model: 'button', icon: 'trash', size: 2, func: this.removeItem },
				{ title: '메모', model: 'memoBtn', domain: 'STORE', size: 2 },
			],
		}
	},
	methods: {
		search() {
			//검색 시작
			const opt = this.opt,
				startDate = getDateValue(opt.startDate),
				endDate = getDateValue(opt.endDate),
				//result = getDropdownValue(opt.result),
				searchVal = opt.searchKeyword

			let data = {
					paging: getPaging(),
					isExcel: 0,
					isWholeDate: opt.period != 'period',
					isExcludeEnded: false,
				},
				//선택 조건에 없는 필수값들 입력
				error = []

			//유효성 검사 및 서버에 보낼 데이터 채우기
			if (opt.period == 'period') {
				if (startDate > endDate) error.push('제안일의 시작일이 종료일보다 큽니다.')
				else {
					data.startDate = startDate.substr(0, 10).replace(/-/g, '')
					data.endDate = endDate.substr(0, 10).replace(/-/g, '')
				}
			}

			if (searchVal) data.searchKey = getSearchKey(searchKeyOpts, searchVal)
			if (error.length) {
				//유효성 검사에서 오류가 있을 경우 알럿
				alert.e(error)
				return false
			} else {
				//오류가 없으면 검색 조건 설정하고 1페이지로 설정
				this.lastOpt = data
				return this.changePage(1)
			}
		},
		changePage(page = 1, ex = false) {
			//마지막 검색 조건을 기준으로 페이지 변경
			this.lastOpt.paging.pageNo = page - 1
			const url = !ex
				? '/dealSelect/dealRestrictedDateList'
				: 'api/backoffice/dealRestrictedDateSelect/downloadDealRestrictedDateList'
			return postApi(url, addExcelData(this, ex)).then(res => {
				if (!ex) {
					res.list = res.list.map(v => {
						v.createDt = v.createDt.replace('T', ' ')
						v.comName = v.companyName
						v.restrictedDate = `${v.startDate.substr(0, 10)} ~ ${v.endDate.substr(0, 10)}`
						return v
					})
					this.res = res
				} else alert.excel()
			})
		},
		excelExport() {
			const opt = this.lastOpt,
				ex = new excel()
			if (!opt.paging) {
				alert.w('검색을 먼저 해주세요')
				return
			}

			ex.date(opt.startDate, opt.endDate, '등록일')
			ex.search(opt.searchKey, searchKeyOpts)

			ex.go(this)
		},
		removeItem(item) {
			confirm('삭제 즉시 해당 기간에 딜 신청이 가능해집니다.\r\n삭제하시겠습니까?', () => {
				putApi('api/backoffice/dealRestrictedDateHandle/deleteDealRestrictedDate', {
					dealRestrictedDateSeq: item.dealRestrictedDateSeq,
				}).then(() => {
					alert.s('딜불가기간 삭제에 성공했습니다')
					reload(this)
				})
			})
		},
	},
	created() {
		this.search()
	},
}
</script>
