<template>
	<basePopup :okFunc="save" title="딜 불가기간 등록">
		<p>1. 상품그룹을 선택하세요.</p>
		<div class="flex">
			<b-form-input
				class="w-1/1 mr-4"
				v-model.trim="opt.searchVal"
				name="keyword"
				placeholder="검색어"
				@keyup.enter="search"
			/>
			<b-button class="w-40 lh-3" @click="search">검색</b-button>
		</div>
		<tb @click="selectItem" :inf="inf" :pr="ths" :res="res" limit="5" selectable />

		<p>2. 딜 진행 불가한 기간을 선택하세요.</p>
		<div class="flex">
			<datePicker class="" model="input.startDate" />
			<span class="p-2 lh-6">~</span>
			<datePicker class="" model="input.endDate" />
		</div>

		<p class="mt-2">3. 관련한 메모를 남겨주세요. 해당 파트너사 메모에 저장됩니다.</p>
		<b-form-textarea class="resize-none" v-model="input.memo" rows="5" />
	</basePopup>
</template>

<script>
import basePopup from 'comp/local/basePopup'

const now = getMonth(),
	searchKeyOpts = [
		{ label: 'idx', value: 'productGroupIdx', operation: 'equal' },
		{
			label: '상품그룹',
			value: 'productGroupName',
		},
		{
			label: '브랜드',
			value: 'brandName',
		},
		{
			label: '파트너사',
			value: 'companyName',
		},
	],
	defInput = {
		startDate: now,
		endDate: now,
		selectedItem: '',
		memo: '',
	}

export default {
	//mixins: [addItem],
	components: { basePopup },
	data() {
		return {
			isShow: false,
			opt: { searchKeyword: '' },
			lastOpt: {},
			res: {},

			inf: [
				{ title: 'idx', key: 'productGroupIdx', size: 2 },
				{ title: '브랜드', key: 'brandName', size: 4 },
				{ title: '상품그룹', key: 'productGroupName', size: 8 },
				{ title: '파트너사', key: 'companyName', size: 4 },
				{ title: '노출등급', key: 'productGroupClass', size: 2 },
			],

			input: {
				...defInput,
			},
		}
	},
	methods: {
		open() {
			const now = new Date()
			this.opt.searchKeyword = ''
			/*Object.keys(defOpt).map(k => {
				this.opt[k] = defOpt[k]
			})*/
			this.input = { ...defInput }

			this.search()
			this.isShow = true
		},
		search() {
			const opt = this.opt,
				data = { paging: getPaging(0, 5) }

			if (opt.searchVal) data.searchKey = getSearchKey(searchKeyOpts, opt.searchVal)
			this.lastOpt = data
			this.changePage()
		},
		changePage(page = 1) {
			this.lastOpt.paging.pageNo = page - 1
			postApi('api/backoffice/dealRestrictedDateSelect/productGroupList', this.lastOpt).then(res => {
				this.res = res
			})
		},
		selectItem(item) {
			this.input.selectedItem = item
		},
		save() {
			const input = this.input

			if (!input.selectedItem) alert.w('상품그룹을 선택해주세요')
			else if (input.startDate < now) alert.w('과거 날짜는 선택할 수 없습니다.')
			else if (input.startDate > input.endDate) alert.w('종료일은 시작일보다 작거나 커야 합니다.')
			else if (input.endDate - input.startDate > 86400000 * 31)
				alert.w('딜 불가기간은 최대 31일까지만 선택할 수 있습니다.')
			else {
				const func = () => {
					postApi(
						'api/backoffice/dealRestrictedDateHandle/insertDealRestrictedDate',
						{
							productGroupIdx: input.selectedItem.productGroupIdx,
							memoText: input.memo,
							startDate: input.startDate.get_time('d', 0, ''),
							endDate: input.endDate.get_time('d', 0, ''),
						},
						false
					).then(res => {
						if (Array.isArray(res) && res.length) {
							let dupDate = res
								.map(v => `${v.startDate.substr(0, 10)} ~ ${v.endDate.substr(0, 10)}`)
								.join('\r\n')
							alert.w(
								'이미 등록된 딜 불가기간과 겹치는 날짜가 있습니다. 기간을 다시 확인해주세요.\r\n\r\n\r겹치는 딜 불가기간 : \r\n' +
									dupDate
							)
						} else {
							alert.s('딜 불가기간 등록이 완료되었습니다', 500)
							this.isShow = false
							reload(this, true)
						}
					})
				}
				confirm(
					"딜 불가기간을 등록하시겠습니까?\r\n등록 즉시 해당 기간에 딜 신청이 불가해지고,\r\n이미 신청된 딜은 '거절' 처리됩니다.",
					func
				)
			}
		},
	},
}
</script>
